@import '../../styles/colors/_colors';

$bgColor: $dark;

.popover {
  &__text {
    margin: 0;
    color: $white;
    font-family: var(--font-family-montserrat);
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }

  &__description {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    cursor: pointer;
  }

  :global {
    & .popover {
      filter: drop-shadow(0px 0px 10px rgba($secondary, 0.5));
      border-radius: 0;
    }

    & .popover-body {
      background-color: $bgColor;
      padding: 8px 16px;
    }

    & .bs-popover-auto[x-placement^='bottom'] .arrow::before,
    & .bs-popover-auto[x-placement^='bottom'] .arrow::after,
    .bs-popover-bottom .arrow::before,
    .bs-popover-bottom .arrow::after {
      border-bottom-color: $bgColor;
    }

    & .bs-popover-auto[x-placement^='top'] .arrow::before,
    & .bs-popover-auto[x-placement^='top'] .arrow::after,
    .bs-popover-top .arrow::before,
    .bs-popover-top .arrow::after {
      border-top-color: $bgColor;
    }

    & .bs-popover-auto[x-placement^='left'] .arrow::before,
    & .bs-popover-auto[x-placement^='left'] .arrow::after,
    .bs-popover-left .arrow::before,
    .bs-popover-left .arrow::after {
      border-left-color: $bgColor;
    }

    & .bs-popover-auto[x-placement^='right'] .arrow::before,
    & .bs-popover-auto[x-placement^='right'] .arrow::after,
    .bs-popover-right .arrow::before,
    .bs-popover-right .arrow::after {
      border-right-color: $bgColor;
    }
  }
}
