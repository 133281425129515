@import '../colors/colors';

.custom-checkbox,
.custom-radio {
  &.status-error .custom-control-label::before {
    box-shadow: 0 0 0px 2px $white, 0 0 0px 3px $danger !important;
  }
  &.status-success .custom-control-label::before {
    box-shadow: 0 0 0px 2px $white, 0 0 0px 3px $success !important;
  }
}

.custom-radio {
  .custom-control-label {
    &::before {
      height: 10px;
      width: 10px;
      margin-top: 3px;
      box-shadow: 0 0 0px 2px $white, 0 0 0px 3px $grey !important;
      border: 0;
      background-color: transparent;
    }
    &::after {
      display: none;
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::before {
        border: 0;
        background-color: $primary-500;
        box-shadow: 0 0 0px 2px $white, 0 0 0px 3px $primary-500 !important;
      }
      &::after {
        display: none;
      }
    }
  }
}

.custom-checkbox {
  .custom-control-label {
    padding-left: 6px;
    padding-top: 2px;
    &::before {
      border: 1.5px solid $primary-500 !important;
      border-radius: 5px;
      min-height: 24px;
      min-width: 24px;
      top: 0;
      pointer-events: none;
    }
    &::after {
      min-height: 24px;
      min-width: 24px;
      top: 0;
      pointer-events: none;
    }
  }
}
