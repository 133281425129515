@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.formContent {
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .inputLabel {
    margin: 0 0 0 0;
  }

  .emailInput,
  .passwordInput {
    width: 100%;
    height: 5rem;

    div > input {
      border-radius: 0.25rem;

      &:focus {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    div > div > div {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  button {
    margin-top: 0.5rem;

    @include media-breakpoint-down(md) {
      margin-top: 7.4rem;
    }
  }
}

.forgotPass {
  width: 100%;
  border: none;
  text-decoration: underline;
  margin-top: 0.5rem;
}
