@import '@objectedge/pecai-storefront-ds/src/styles/variables';
@import '../../../src/styles/colors/colors';

.coupons {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
  gap: 2px;
  color: #4a2f0d;

  strong {
    font-weight: 600;
  }
}

.couponChip {
  width: max-content;
  border-radius: 8px;
  background: #ffcc81;
  display: flex;
  gap: 4px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  line-height: 120%;
}
