@import '../../styles/variables';

.btn {
  font-family: $font-family;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 100% !important;
  border-radius: 8px !important;

  .circle {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .circleLg {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &-size-md {
    height: 52px;
  }

  &-size-sm {
    padding: 10px 24px;
  }

  &--light {
    color: $secondary;

    &:hover {
      background-color: $light-grey;
    }

    &:focus {
      background-color: $light-grey;
    }
  }
}
