@import '../../styles/variables.scss';
@import '../../styles/colors/colors';

.main {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__circle {
      width: 10px;
      height: 10px;
      background: $grey;
      border-radius: 50%;
      margin: 0 20px;

      &--active {
        background: $primary;
      }
    }
  }

  &__collapse {
    margin: 20px;
  }
}
