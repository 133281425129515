$dark: #5c656f;
$dark-hover: #7d8895;

$white: #fffdfa;

$black: #000000;

$gray-50: #f1f0f5;

$dark-grey: #636e72;
$grey: #b2bec3;
$grey-pressed: #939ba4;
$grey-hover: #c3ceda;
$light-grey: #dfe6e9;
$soft-grey: #f9f9f7;
$mid-grey: #636e72;

$gray-500: #1d1a16;
$gray-400: #373431;
$gray-300: #514f4b;
$gray-200: #6b6966;
$gray-100: #858381;
$gray-50: #e1e0e7;

$light: #f8fafb;
$light-hover: #f9f9f9;
$light-pressed: #e3e3e3;

$info: #1698af;
$info-pressed: #137c8e;
$info-hover: #23bdd8;

$links: #3184e3;
$link-pressed: #296fbf;
$links-hover: #3e96fa;

$warning: #f6bb22;
$warning-pressed: #e2a916;
$warning-hover: #ffce51;

$danger: #eb5757 !default;
$danger-pressed: #d04444;
$danger-hover: #ff8484;

$success: #47c782;
$success-pressed: #3bae70;
$success-hover: #62e79f;

$secondary: #333237;
$secondary-500: #333338;
$secondary-900: #0f0f11;
$secondary-pressed: #28272d;
$secondary-hover: #5a5961;

$secondary-900: #0f0f11;
$secondary-800: #18181b;
$secondary-700: #212124;
$secondary-600: #2a2a2e;
$secondary-500: #333338;
$secondary-400: #4a4a4f;
$secondary-300: #626266;
$secondary-200: #79797d;
$secondary-100: #919194;

$primary: #f49d29 !default;
$primary-light: #fef5e9;
$primary-pressed: #e18b17;
$primary-hover: #fab457;

$primary-900: #4a2f0d;
$primary-800: #744b14;
$primary-700: #9f661b;
$primary-600: #ca8223;
$primary-500: #f59d2a;
$primary-400: #fba93e;
$primary-300: #feb04b;
$primary-200: #ffc06a;
$primary-100: #ffcc81;

$accent: #bb6bd9;

$font-color-1: #4A2F0D;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'light-hover': $soft-grey,
  'light-pressed': $light-grey,
  'dark': $dark,
  'white': $white,
  'black': $black,
  'accent': $accent,
  'dark-grey': $dark-grey,
  'grey': $grey,
  'light-grey': $light-grey,
  'soft-grey': $soft-grey,
  'mid-grey': $mid-grey,
  'links': $links,
  'primary-light': $primary-light,
  'primary-hover': $primary-hover,
  'primary-pressed': $primary-pressed,
  'secondary-hover': $secondary-hover,
  'secondary-pressed': $secondary-pressed,
  'danger-hover': $danger-hover,
  'danger-pressed': $danger-pressed,
  'success-hover': $success-hover,
  'success-pressed': $success-pressed,
  'warning-hover': $warning-hover,
  'warning-pressed': $warning-pressed,
  'primary-900': $primary-900,
  'primary-800': $primary-800,
  'primary-700': $primary-700,
  'primary-600': $primary-600,
  'primary-500': $primary-500,
  'primary-400': $primary-400,
  'primary-300': $primary-300,
  'primary-200': $primary-200,
  'primary-100': $primary-100,
  'secondary-900': $secondary-900,
  'secondary-800': $secondary-800,
  'secondary-700': $secondary-700,
  'secondary-600': $secondary-600,
  'secondary-500': $secondary-500,
  'secondary-400': $secondary-400,
  'secondary-300': $secondary-300,
  'secondary-200': $secondary-200,
  'secondary-100': $secondary-100,
  'gray-500': $gray-500,
  'gray-400': $gray-400,
  'gray-300': $gray-300,
  'gray-200': $gray-200,
  'gray-100': $gray-100,
  'gray-50': $gray-50,
);

@mixin createPalette($property) {
  &-primary {
    #{$property}: $primary;
  }
  &-secondary {
    #{$property}: $secondary;
  }
  &-default {
    #{$property}: $secondary;
  }
  &-info {
    #{$property}: $info;
  }
  &-danger {
    #{$property}: $danger;
  }
  &-success {
    #{$property}: $success;
  }
  &-warning {
    #{$property}: $warning;
  }
  &-light {
    #{$property}: $light;
  }
  &-grey {
    #{$property}: $grey;
  }
  &-mid-grey {
    #{$property}: $mid-grey;
  }
}
