@import '../../styles/variables';

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  border: none;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  background-color: transparent;

  &__label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    &--small {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__icon {
    margin-top: 4px;

    &-primary {
      > path {
        fill: $primary;
      }
    }
    &-secondary {
      > path {
        fill: $secondary;
      }
    }
    &-default {
      > path {
        fill: $secondary;
      }
    }
    &-info {
      > path {
        fill: $info;
      }
    }
    &-danger {
      > path {
        fill: $danger;
      }
    }
    &-success {
      > path {
        fill: $success;
      }
    }
    &-warning {
      > path {
        fill: $warning;
      }
    }
    &-light {
      > path {
        fill: $light;
      }
    }
    &-grey {
      > path {
        fill: $grey;
      }
    }
  }
}
