@import '../../styles/colors/_colors';

.tooltip {
  :global {
    & .tooltip {
      filter: drop-shadow(0px 0px 10px rgba($secondary, 0.5));
    }

    & .bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
    .bs-tooltip-bottom .arrow::before {
      border-bottom-color: $dark;
    }

    & .bs-tooltip-auto[x-placement^='top'] .arrow::before,
    .bs-tooltip-top .arrow::before {
      border-top-color: $dark;
    }

    & .bs-tooltip-auto[x-placement^='left'] .arrow::before,
    .bs-tooltip-left .arrow::before {
      border-left-color: $dark;
    }

    & .bs-tooltip-auto[x-placement^='right'] .arrow::before,
    .bs-tooltip-right .arrow::before {
      border-right-color: $dark;
    }
  }
}

.tooltip-content {
  background-color: $dark;
  border-radius: 0;
  color: $white;
  font-family: var(--font-family-montserrat);
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  padding: 8px 16px;
}

.width-unset {
  max-width: unset;
}
