@import '../../styles/colors/colors';

.alert {
  display: flex;
  align-items: center;
  padding: 27px 16px;
  border: 1px solid rgba(51, 50, 55, 0.25);
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 28px;

  &.shadow {
    box-shadow: 0px 5px 5px rgba(51, 50, 55, 0.25);
  }

  &--small {
    width: 288px !important;
    height: 32px !important;
  }

  &.color {
    &-light {
      background-color: $white;
      color: $primary !important;
    }

    &-danger {
      background-color: $danger;
      color: $white !important;
    }

    &-success {
      background-color: $success;
      color: $white !important;
    }
  }

  &-content {
    flex: 1;
    padding-right: 38.5px;
  }

  .close-button {
    cursor: pointer;
  }
}
