.checkbox {
  width: 100%;
  display: block;
  margin-bottom: 0.5rem;

  &-text {
    &-nowrap {
      white-space: nowrap;
    }
  }
}

.checkbox-container {
  display: flex;
  align-items: center;

  > div {
    display: table;

    > label {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
