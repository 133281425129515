@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.modalContent {
  div > div {
    padding-bottom: 0;
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20%;
  .header {
    border-bottom: 4px solid $primary-100;
    border-radius: 2px;
    padding-bottom: 15px;
    svg {
      width: 60px;
      height: 60px;
      margin-right: 15px;
    }
  }
  p {
    padding: 45px 95px;
    text-align: center;
  }
  button {
    border-radius: 10px;
    font-weight: 700;
    padding: 15px;
    width: 95%;
  }
}
