body {
  overflow-x: hidden;
  max-width: 100vw;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-open {
  padding-right: 0 !important;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Hide grecaptcha badge without compromising the spam verification
.grecaptcha-badge {
  opacity: 0;
}

:root {
  --font-family: 'Roboto', 'Montserrat', sans-serif;
  --font-family-montserrat: 'Montserrat', 'Roboto', sans-serif; // overridden from DS for tunne-parts typography update
}
