@import '@objectedge/pecai-storefront-ds/src/styles/variables';
@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.stepsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .stepOneContainer,
  .stepTwoContainer,
  .stepThreeContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &::after {
      margin-top: 1rem;
      content: '';
      border-bottom: 4px solid $gray-50;
      border-radius: 8px;
      width: 100%;
    }

    .contentContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .textsContainer {
        margin-left: 0.5rem;

        & > p {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .dark {
    &::after {
      border-bottom: 4px solid $secondary-500 !important;
    }
  }
}

.mobileStepsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .stepOneContainer,
  .stepTwoContainer,
  .stepThreeContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &::after {
      content: '';
      position: relative;
      left: 11px;
      border-bottom: 4px solid $gray-50;
      border-radius: 8px;
      width: 120%;
    }

    .contentContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .upperContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & > p {
          margin-left: 0.3rem;
          white-space: nowrap;
        }
      }

      .stepOne {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      .stepTwo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      .bottomContainer {
        width: 120%;
        padding: 0.5rem 0 0 0.8rem;
        white-space: nowrap;
      }

      .noText {
        p {
          color: $white;
          position: relative;
          z-index: -10;
        }
      }
    }
  }
  .dark {
    &::after {
      border-bottom: 4px solid $secondary-500 !important;
      z-index: 10;
    }
  }

  .stepTwoContainer {
    .contentContainer {
      .bottomContainer {
        padding-left: 5px;
      }
    }
  }

  .stepThreeContainer {
    &::after {
      left: -5px;
    }

    .contentContainer {
      .bottomContainer {
        padding-left: 0;
        margin-left: -13px;
      }
    }
  }
}

.formContent {
  margin-top: 2rem;

  .inputLabel {
    display: inline;
    p {
      margin-bottom: -8px;
      font-family: 'Montserrat' !important;
    }
  }

  .labelCepContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .inputLabelCep {
      display: inline;
      margin-bottom: 0;
    }
  }

  .formInput {
    width: 100%;
    height: 5rem;
    background-color: white;

    div > input {
      border-radius: 0.25rem;

      &:focus {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    div > div > div {
      margin-top: auto;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .formSelect {
    div > div > div {
      border-radius: 0.5rem;
      padding: 3px;

      div > div {
        font-family: 'Roboto';
      }
    }
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    margin-top: 2.5rem;
    justify-content: space-between;
    button {
      width: 172px;
      padding: 10px 0;
    }
  }
}

.checkCircle {
  margin: 3rem 0 3rem 0;

  @include media-breakpoint-down(md) {
    margin: 4rem 0 5rem 0;
  }

  @include media-breakpoint-down(sm) {
    margin: 3rem 0 9rem 0;
  }
}

input.simple {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  &:disabled {
    visibility: hidden;
  }
  height: 25px;
  outline: 0;
  border-width: 0 0 1px;
  border-color: gray;

  &:focus {
    outline: 0;
  }
}

.itemsAvailable {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 1.5rem;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}
