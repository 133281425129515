@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.loader {
  &-container {
    align-items: center;
    background: rgba($white, 0.8);
    display: grid;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    &.full {
      position: fixed;
      z-index: 100001;
    }
    &.constrained {
      height: 100%;
      top: auto;
      left: auto;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-logotype {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 4s linear infinite;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;

    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
  }

  &-message {
    color: $primary !important;
    margin-top: 16px;
    margin-bottom: 0px;
  }
}
