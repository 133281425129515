@import './colors/_colors';

@import 'bootstrap/scss/_mixins';
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';

// Grid

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
);

$modal-lg: 949px;

$font-family-montserrat: 'Montserrat', sans-serif;
$font-family: 'Roboto', sans-serif !default;
