@import './../../styles/_variables';

$default-font-weight: normal;
$default-line-height: 1.2;
$default-letter-spacing: normal;

@mixin resetStyles {
  font-weight: $default-font-weight;
  line-height: $default-line-height;
  letter-spacing: $default-letter-spacing;
}

@mixin headline {
  font-weight: 700;
  line-height: $default-line-height;
  letter-spacing: $default-letter-spacing;
  font-family: var(--font-family-montserrat);
}

@mixin h1 {
  @include headline;
  font-size: 3rem;
  @include media-breakpoint-down(lg) {
    font-size: 2rem;
  }
}

@mixin h2 {
  @include headline;
  font-size: 2.5rem;
  @include media-breakpoint-down(lg) {
    font-size: 1.5rem;
  }
}

@mixin h3 {
  @include headline;
  font-weight: 600;
  font-size: 2rem;
  @include media-breakpoint-down(lg) {
    font-weight: 700;
    font-size: 1.25rem;
  }
}

@mixin h4 {
  @include headline;
  font-weight: 600;
  font-size: 1.75rem;
  @include media-breakpoint-down(lg) {
    font-weight: 700;
    font-size: 1.125rem;
  }
}

@mixin h5 {
  @include headline;
  font-weight: 600;
  font-size: 1.5rem;
  @include media-breakpoint-down(lg) {
    font-weight: 700;
    font-size: 1rem;
  }
}

@mixin h6 {
  @include headline;
  font-weight: 600;
  font-size: 1.25rem;

  @include media-breakpoint-down(lg) {
    font-weight: 700;
    font-size: 75%;
  }
}

@mixin body {
  @include resetStyles;
  font-family: $font-family;
  font-weight: 400;
  line-height: 150%;
}

@mixin body-xlarge {
  @include body;
  font-size: 1.25rem;
}

@mixin body-large {
  @include body;
  font-size: 1.125rem;
}

@mixin body-regular {
  @include body;
  font-size: 1rem;
}

@mixin body-small {
  @include body;
  font-size: 0.875rem;

  @include media-breakpoint-down(lg) {
    font-size: 70%;
  }
}

@mixin body-xsmall {
  @include body;
  font-size: 0.75rem;
}

@mixin body-text {
  @include resetStyles;
}

@mixin body-text-1 {
  @include body-text;
  font-size: 1rem;
  @include media-breakpoint-down(sm) {
    font-size: 0.875rem;
  }
}

@mixin body-text-2 {
  @include body-text;
  font-size: 0.875rem;
  @include media-breakpoint-down(sm) {
    font-size: 0.75rem;
  }
}

@mixin caption {
  @include resetStyles;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 110%;
  font-family: var(--font-family-montserrat);
}

@mixin caption-small {
  @include caption;
  font-size: 0.75rem;
  line-height: 120%;
  text-transform: capitalize;
}

@mixin caption-1 {
  @include caption;
  font-size: 0.75rem;
}

@mixin caption-2 {
  @include caption;
  font-size: 0.5625rem;
}

@mixin input {
  font-weight: $default-font-weight;
  font-size: 16px;
  line-height: 20px;
}

@mixin input-label {
  font-weight: $default-font-weight;
  font-size: 12px;
  line-height: 15px;
}

@mixin display {
  font-weight: bold !important;
}

@mixin display-1 {
  @include display;
  font-size: 44px !important;
  line-height: 120% !important;
  letter-spacing: 1.2px !important;
}

@mixin display-2 {
  @include display;
  font-size: 38px !important;
  line-height: 130% !important;
  letter-spacing: $default-letter-spacing !important;
}

@mixin display-3 {
  @include display;
  font-size: 28px !important;
  line-height: 150% !important;
  letter-spacing: $default-letter-spacing !important;
}

@mixin display-4 {
  font-size: 28px !important;
  font-weight: $default-font-weight !important;
  line-height: 130% !important;
  letter-spacing: $default-letter-spacing !important;
}

@mixin button {
  @include resetStyles;
}

@mixin button-large {
  @include button;
  font-size: 1.25rem;
}

@mixin button-normal {
  @include button;
  font-size: 1rem;
}

@mixin button-small {
  @include button;
  font-size: 0.875rem;
}

.typography {
  margin: 0;
  font-family: var(--font-family-montserrat);

  &-font-weight-bold {
    font-weight: 700 !important;
  }

  &-font-weight-semibold {
    font-weight: 600 !important;
  }

  &-font-weight-medium {
    font-weight: 500 !important;
  }

  &-font-weight-normal {
    font-weight: 400 !important;
  }

  &-font-weight-light {
    font-weight: 300 !important;
  }

  &-letter-spacing-1-2 {
    letter-spacing: 1.2px;
  }

  &-gutter-bottom {
    margin-bottom: 16px;
  }

  @include createPalette('color');

  &.h1 {
    @include h1;
  }

  &.h2 {
    @include h2;
  }

  &.h3 {
    @include h3;
  }

  &.h4 {
    @include h4;
  }

  &.h5 {
    @include h5;
  }

  &.h6 {
    @include h6;
  }

  &-body {
    &-xlarge {
      @include body-xlarge;
    }

    &-large {
      @include body-large;
    }

    &-regular {
      @include body-regular;
    }

    &-small {
      @include body-small;
    }

    &-xsmall {
      @include body-xsmall;
    }
  }

  &-body-text {
    &-1 {
      @include body-text-1;
    }

    &-2 {
      @include body-text-2;
    }
  }

  &-caption {
    @include caption;
    &-1 {
      @include caption-1;
    }

    &-2 {
      @include caption-2;

      &-strong {
        font-size: 0.75rem;
        font-weight: 600;
      }
    }
    &-small {
      @include caption-small;
    }
  }

  &-input {
    @include input;

    &-label {
      @include input-label;
    }
  }

  &-display {
    &-1 {
      @include display-1;
    }

    &-2 {
      @include display-2;
    }

    &-3 {
      @include display-3;
    }

    &-4 {
      @include display-4;
    }
  }

  &-button {
    &-large {
      @include button-large;
    }

    &-normal {
      @include button-normal;
    }

    &-small {
      @include button-small;
    }
  }

  @include media-breakpoint-down(lg) {
    &.m {
      font-weight: normal;
    }
    &.m-h1 {
      @include h1;
    }

    &.m-h2 {
      @include h2;
    }

    &.m-h3 {
      @include h3;
    }

    &.m-h4 {
      @include h4;
    }

    &.m-h5 {
      @include h5;
    }

    &.m-h6 {
      @include h6;
    }

    &.m-body-text {
      &-1 {
        @include body-text-1;
      }

      &-2 {
        @include body-text-2;
      }
    }

    &.m-body-text-2 {
      @include body-text;
      @include body-text-2;
    }

    &.m-caption {
      &-1 {
        @include caption-1;
      }

      &-2 {
        @include caption-2;
      }
    }

    &.m-input {
      @include input;
    }

    &.m-input-label {
      @include input-label;
    }

    &.m-display {
      &-1 {
        @include display-1;
      }

      &-2 {
        @include display-2;
      }

      &-3 {
        @include display-3;
      }

      &-4 {
        @include display-4;
      }
    }

    &.m-button {
      &-large {
        @include button-large;
      }

      &-normal {
        @include button-normal;
      }

      &-small {
        @include button-small;
      }
    }
  }
}
