@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.alert-container {
  box-sizing: border-box;
  left: 50%;
  margin: auto;
  max-width: 755px;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
  z-index: 100000;

  @include media-breakpoint-up('lg') {
    bottom: 112px;
  }

  @include media-breakpoint-down('md') {
    bottom: 72px;
    padding: 0 16px;
  }
}
