@import './../../../styles/colors/colors';

.select {
  margin-top: 13px;

  &-disabled {
    *,
    input {
      color: $light-grey;
    }
    input {
      background-color: $light !important;
      border-color: $light-grey !important;
    }
  }
  &-label {
    width: 100%;
  }
  &-helper-text {
    width: 100%;
    flex: 1;
  }
}

.labelContainer {
  position: absolute;
  z-index: 1;
  margin-left: 3px;
  margin-top: 14px;
  transition: all 0.1s ease-out;
  pointer-events: none;

  hr {
    display: none;
    border-top: 2px solid $white;
    margin: -11px 0 0 0;
    width: 100%;
    left: 0;
  }

  label {
    font-size: 16px !important;
    padding: 0 8px;
    color: $grey;
    transition: all 0.1s ease-out;
  }

  &.focus {
    margin-top: -13px !important;
    margin-left: 10px !important;
    hr {
      display: block;
    }
    label {
      font-size: 12px !important;
    }
  }

  &.withPrefixx {
    margin-left: 45px;
  }
}

.text {
  pointer-events: none;
}

.icon {
  margin: 12px 0;
  margin-right: 12px;
  cursor: pointer;
  path {
    stroke: $grey;
  }
}
