@import '../../styles/variables.scss';
@import '../../styles/colors/colors';

.breadcrumb {
  ol {
    background-color: transparent;
    margin: 0;
    padding: 0;
  }

  .breadcrumb-item {
    color: $secondary;
    text-decoration: none;
    font-family: var(--font-family-montserrat);
    font-size: 14px;
    line-height: 155%;
    cursor: pointer;

    &:hover {
      color: $secondary-hover;
    }

    &:focus {
      color: $secondary-pressed;
    }

    & + .breadcrumb-item::before {
      color: $secondary;
      content: '❯';
    }

    &--light {
      color: $light-grey;

      & + .breadcrumb-item::before {
        color: $light-grey;
        content: '❯';
      }
    }

    &--small {
      font-size: 12px;
      line-height: 15px;
    }

    &--medium {
      font-size: 16px;
      line-height: 150%;
    }

    &--large {
      font-size: 32px;
      line-height: 120%;
    }

    &--active {
      font-family: var(--font-family-montserrat);
      color: $grey;
      cursor: default;

      &:hover {
        color: $grey;
      }
    }

    &--primary {
      font-family: var(--font-family-montserrat);
      color: $primary;
      cursor: default;

      &:hover {
        color: $primary;
      }
    }
  }
}
