@import './../../styles/colors/colors';

@mixin createSvgColor($property) {
  &-primary {
    color: $primary;
  }
  &-secondary {
    color: $secondary;
  }
  &-default {
    color: $secondary;
  }
  &-white {
    color: $white;
  }
  &-info {
    color: $info;
  }
  &-danger {
    color: $danger;
  }
  &-warning {
    color: $warning;
  }
  &-success {
    color: $success;
  }
  &-grey {
    #{$property}: $grey;
    @if $property == stroke {
      fill: none;
    }
  }
  &-light-grey {
    #{$property}: $light-grey;
    @if $property == stroke {
      fill: none;
    }
  }
  &-light {
    color: $light;
  }
}

.svg-icon {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  flex-shrink: 0;

  &-variant {
    &-16 {
      font-size: 16px;
    }
    &-24 {
      font-size: 24px;
    }
  }

  &-color {
    @include createSvgColor(fill);
  }

  &-color-fill {
    > path {
      fill: currentColor;
    }
  }

  &-color-stroke {
    > path {
      fill: none;
      stroke: currentColor;
    }
  }
}
