@import '../../../styles/colors/_colors';

@keyframes onAutoFillStart {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@mixin applyStatus($color) {
  label {
    color: $color !important;
  }

  input,
  .appendix,
  .prefix {
    border: 1px solid $color;
    border-radius: 8px !important;
    box-sizing: border-box;
    background: transparent !important;

    &:focus {
      border: 1px solid $color;
      box-sizing: border-box;
      box-shadow: none;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $secondary;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $secondary;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $secondary;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: $secondary;
      }
    }

    &:-webkit-autofill {
      // Expose a hook for JS onAutoFillStart
      // JavaScript can capture 'animationstart' events
      // This allows the autofill to be detected when it doesn't fire the onChange event
      animation-name: onAutoFillStart;
    }
  }
}

.default {
  @include applyStatus($grey);
}
.success {
  @include applyStatus($success);
}
.danger {
  @include applyStatus($danger);
}
.warning {
  @include applyStatus($warning);
}

.textfield {
  margin-bottom: 0px;

  .labelContainer {
    position: absolute;
    z-index: 5;
    margin-left: 10px;
    margin-top: 28px;
    transition: all 0.1s ease-out;
    pointer-events: none;

    hr {
      display: none;
      border-top: 2px solid white;
      margin: -11px 0 0 0;
      width: 100%;
      left: 0;
    }

    label {
      font-size: 16px !important;
      padding: 0 8px;
      color: $grey;
      transition: all 0.1s ease-out;
    }

    &.focus {
      margin-top: 0px !important;
      margin-left: 10px !important;
      hr {
        display: block;
      }
      label {
        font-size: 12px !important;
      }
    }

    &.withPrefixx {
      margin-left: 45px;
    }
  }

  .text {
    pointer-events: none;
  }

  &.disabled {
    * {
      color: $grey;
    }
    input {
      background-color: $light !important;
      border-color: $light-grey !important;
    }
  }

  .input {
    font-family: var(--font-family-montserrat);
    margin-top: 13px;
    border-radius: 0;
    padding: 16px;
    height: 52px;

    &.withPrefix {
      border-left: none !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      padding-left: 8px;
    }

    &.withAppendix {
      border-right: none !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  .appendix,
  .prefix {
    height: 52px;
    width: 45px;
    display: flex;
    align-items: center;
    margin-top: 13px;
    border-radius: 0;
  }

  .appendix {
    border-left: 0 !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    justify-content: space-around;
  }
  .prefix {
    justify-content: flex-end;
    border-right: 0 !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    img {
      width: 32px;
      height: 32px;
    }
  }

  &.small {
    .input,
    .appendix,
    .prefix {
      height: 48px;
    }

    .labelContainer {
      margin-top: 26px;
    }
  }

  .charCounter {
    padding-top: 2px;
    float: right;
  }
}
