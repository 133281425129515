@import '@objectedge/pecai-storefront-ds/src/styles/variables';
@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.resetForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    height: 30rem;
  }

  .logoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logoTitle {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: row;

      p {
        @include media-breakpoint-down(sm) {
          font-size: 1.5rem !important;
        }
      }
    }

    .titleLine {
      margin-top: 0.5rem;
      width: 60%;
      border-top: 4px solid $primary-500;
      border-radius: 0.5rem;
    }
  }

  .checkCircle {
    margin: 3rem 0 3rem 0;

    @include media-breakpoint-down(md) {
      margin: 4rem 0 5rem 0;
    }

    @include media-breakpoint-down(sm) {
      margin: 3rem 0 9rem 0;
    }
  }

  .formContent {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .inputLabel {
      margin: 1rem 0 0 0;
    }

    .emailInput {
      width: 100%;
      height: 5rem;

      div > input {
        border-radius: 0.25rem;

        &:focus {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }

      div > div > div {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .bottomLabel {
      margin: -0.5rem 0 1.7rem 0;

      @include media-breakpoint-down(md) {
        margin: -0.5rem 0 6.7rem 0;
      }

      @include media-breakpoint-down(sm) {
        margin: -0.5rem 0 13rem 0;
      }
    }
  }

  .backToLogin {
    width: 100%;
    text-decoration: underline;
    margin-top: 0.5rem;
  }
}

