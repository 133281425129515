@import '../variables.scss';

.custom-slick {
  .slick-dots {
    height: 10px;
    li {
      margin: 0;
      button::before {
        color: $grey;
        font-size: 18px;
      }
      &.slick-active button::before {
        color: $primary;
      }
    }
  }
}
