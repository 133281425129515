@import '../../styles/variables.scss';

@mixin defaultDropdown(
  $border-color,
  $bg-color,
  $color: $secondary,
  $h-color: $secondary
) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  color: $color;

  &:hover {
    color: $h-color;
  }
}

@mixin defaultDropdownItem() {
  &:hover {
    background-color: $white;
    color: $black;
  }
}

.dropdown-wrapper {
  &__flow-inline {
    display: inline-flex;
  }
  &__flow-block {
    display: flex;
  }

  :global .dropdown-menu {
    background-color: transparent;
    border-radius: initial;
    border: none;
    margin: 0;
    min-width: auto;
    padding: 0;
    padding-top: 4px;
  }
}

.dropdown {
  align-items: center;
  border-radius: 0;
  column-gap: 10px;
  cursor: pointer;
  font-family: var(--font-family-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  grid-template-columns: auto auto;
  justify-content: space-between;
  line-height: 20px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;

  &__size-small {
    padding: 10px 12px;
  }
  &__size-default {
    padding: 16px;
  }

  &__flow-inline {
    display: inline-grid;
  }
  &__flow-block {
    display: grid;
    width: 100%;
  }

  &--with-icon {
    grid-template-columns: auto auto;
  }
  &--without-icon {
    grid-template-columns: auto;
  }

  &__icon {
    &--dark path {
      fill: $white;
    }
  }

  &__theme-light {
    @include defaultDropdown($light-grey, $white);
  }
  &__theme-light-grey {
    @include defaultDropdown($secondary, rgba($light-grey, 0.25));
  }
  &__theme-dark-grey {
    @include defaultDropdown($secondary, rgba($light-grey, 0.75));
  }
  &__theme-dark {
    @include defaultDropdown($secondary, $secondary, $white, $white);
  }

  &__content {
    background-clip: padding-box;
    background-color: $white;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  &__item {
    color: $secondary;
    font-family: var(--font-family-montserrat);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;

    &:first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }

    :global :local(&).active {
      background-color: rgba($primary, 0.1);
      color: $secondary;
    }

    &:hover {
      color: $secondary;
      background-color: rgba($primary, 0.1);
    }
  }
}
