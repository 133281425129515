@import '@objectedge/pecai-storefront-ds/src/styles/variables';
@import '../../../src/styles/colors/colors';

.productCard {
  box-shadow: 0 0.25rem 0.25rem rgba(151, 151, 151, 0.25);
  border: 1px solid $gray-50;
  border-radius: 0.5rem;
  width: 280px;
  padding: 2em;
  height: 100%;

  .upperTag {
    position: absolute;
    margin-top: -2rem;
    margin-left: -2rem;
  }

  .price {
    font-size: 1.75rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.25rem;
    }
  }

  .pricesContainer {
    flex: 1;
  }

  .unitPriceContainer {
    border-radius: 8px;

    background-color: #f59d2a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 14px 4px 9px;
    margin-top: 9px;
  }

  .unitPrice {
    font-size: 0.75rem;
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .descriptionContainer {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      align-items: center;

      * {
        width: 100%;
      }

      .detailsList {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;

        p {
          display: list-item;
          margin-left: 24px;
          padding-right: 24px;
          margin-bottom: 0.25rem;
        }
      }

      img {
        width: 11.5rem;
        height: 11.5rem;

        @include media-breakpoint-down(lg) {
          width: 6.5rem;
          height: 6.5rem;
        }
      }
    }

    .interestMessage {
      height: 1.313rem;
    }
  }

  &.dense {
    width: 230px;
    padding: 1em;
    .upperTag {
      margin-top: -1rem;
      margin-left: -1rem;
    }
  }

  @include media-breakpoint-down(lg) {
    width: 200px;
    padding: 1em;

    &.dense {
      width: 160px;
      padding: 0.5em;
    }
  }

  @media screen and (max-width: 560px) {
    width: 180px;
    padding: 0.5em;

    &.dense {
      width: 150px;
    }
  }
}

.vouchers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
  gap: 4px;
  color: #4a2f0d;

  strong {
    font-weight: 600;
  }
}

.vouchersChip {
  border-radius: 8px;
  background: #ffcc81;
  display: flex;
  gap: 4px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  line-height: 120%;
}
