@import '../../styles/variables.scss';
@import '../../styles/colors/colors';

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .main {
    min-width: 200px;
    height: 52px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid $secondary;
    background-color: $white;
    cursor: pointer;

    &--small {
      height: 32px;
    }

    &--three {
      width: 133px;
    }

    &--four {
      width: 100px;
    }

    &--selected {
      background-color: $secondary;
    }

    &:hover {
      background-color: $secondary;

      .text {
        color: $white;
      }
    }

    .text {
      color: $secondary;

      &--selected {
        color: $white;
      }
    }
  }
}
