@import './../Typography/Typography.module.scss';

.main {
  border-radius: 8px !important;
  display: inline;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 700;
  text-transform: uppercase !important;

  &.lg {
    padding: 0.5rem;
    font-size: 16px;
    line-height: 150%;
  }

  &.sm {
    padding: 4px 8px;
    @include caption-small;
  }
}
