@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.modalContent {
  margin-left: 7rem;
  max-width: 37rem;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    min-height: 32rem;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    min-height: 32rem;
  }

  div:nth-child(3) {
    width: 100%;

    > div:nth-child(2) {
      padding: 0;
    }
  }
}
