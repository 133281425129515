@import '../../styles/variables.scss';
@import '../../styles/colors/colors';


.chip {
  width: fit-content;
  height: min-content;

  background-color: #fce1bd;
  border-radius: 4px;

  display: flex;
  align-items: center;

  padding: 6px;
  text-align: center;
}
