@import './_variables';

@keyframes opacityPulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

@-webkit-keyframes opacityPulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

.color-grey {
  color: $grey !important;
}

.color-mid-grey {
  color: $mid-grey !important;
}

.color-light-grey {
  color: $light-grey !important;
}

.color-danger {
  color: $danger !important;
}

.color-white {
  color: $white !important;
}

.cursor-pointer * {
  cursor: pointer !important;
}

.w-auto {
  width: auto !important;
}

.bg-light-grey {
  background-color: $light-grey !important;
}

.bg-md-white {
  @include media-breakpoint-up(md) {
    background-color: $white !important;
  }
}

.border-md-grey {
  @include media-breakpoint-up(md) {
    border: 1px solid $grey !important;
  }
}

.loading-overlay {
  cursor: progress;
  position: absolute;
  z-index: 2;
  background-color: $white;
  animation: opacityPulse 1s ease-in;
  animation-iteration-count: infinite;
  width: 100%;
  height: 100%;
}

.mx--15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.my--15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

a.reset-style {
  color: unset;
  &:hover {
    text-decoration: none;
    color: unset;
  }
}

.with-scrollbar {
  &::-webkit-scrollbar {
    cursor: pointer;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: $grey;
    border-radius: 2px;
  }
}

.dashed {
  text-decoration: line-through;
}

.z-index9 {
  z-index: 9;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1rem;
}
